/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import '@babel/polyfill';
import 'url-polyfill';
import 'whatwg-fetch';
import { Turbo } from "@hotwired/turbo-rails"
import Trix from "trix";
import 'controllers';
import { setTrixContentAnchorTargets } from '../global/trix-content-anchor-target';

// Open links inside .trix-content elements in new tabs.
document.addEventListener('turbo:load', setTrixContentAnchorTargets);

// Trix adds - in Firefox sometimes, in Chrome always - the default toolbar above the customized
// toolbar. That's why I set the default toolbar to the empty string. Not an elegant solution
// though...
Trix.config.toolbar.getDefaultHTML = function() { return ''; }

Turbo.session.drive = false

Turbo.setConfirmMethod((message, element) => {
  // message is the string passed via data-turbo-confirm. It can be one of the following:
  //
  // - 'true': We show the default confirm modal.
  // - '{JSON string}': The confirm modal is shown with the specified title, message and
  //   confirm_caption.
  // - '{some message}': The confirm modal is shown without title, the specified message and the
  //   default caption of the confirm button.

  let queryString;

  if (message == 'true') {
    queryString = 'default=true';
  } else {
    let params;

    try {
      let messageObj = JSON.parse(message);
      params = {
        title: messageObj['title'] || '',
        message: messageObj['message'] || '',
        confirm_caption: messageObj['confirm_caption'] || ''
      };
    } catch(e) {
      params = { message };
    }

    queryString = new URLSearchParams(params).toString();
  }
  

  // Show the confirmation modal
  Turbo.visit(
    `/${document.documentElement.lang}/confirm?${queryString}`,
    { frame: 'modal' }
  );

  // The modal dispatches 'confirm-cancel' if the user clicks the cancel button and 'confirm' if she
  // clicks the confirm button.
  // The function parameter of setConfirmMethod can return true or false (depending on whether the
  // action which needs confirmation should actually happen). But the return value can also be a
  // promise, see:
  // https://github.com/hotwired/turbo-rails/blob/1684d35e08a0980545071ca9c5a4aec453c27f59/app/assets/javascripts/turbo.js#L3371
  // https://github.com/hotwired/turbo-rails/blob/1684d35e08a0980545071ca9c5a4aec453c27f59/app/assets/javascripts/turbo.js#L692
  return new Promise((resolve, reject) => {
    document.addEventListener(
      'confirm-cancel',
      () => { resolve(false); },
      { once: true }
    );
    document.addEventListener(
      'confirm',
      () => { resolve(true); },
      { once: true }
    );
  });
});

global.$ = require('jquery');
global.jQuery = require('jquery');

// Support component names relative to this directory:
var componentRequireContext = require.context('components', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

// Load React components on turbo visit. See https://github.com/reactjs/react-rails/issues/1103
ReactRailsUJS.handleEvent('turbo:render', function() { ReactRailsUJS.mountComponents(); })
