import React from 'react';
import ProjectList from './../ProjectList';
import ProjectMap from './../ProjectMap';
import s from './style.module';
import { map, reduce, forEach } from 'lodash';
import ResultContainer from './ResultsContainer';
import cN from 'classnames';
import api from './api';
import { format } from 'date-fns';

import germanLanguageSkillsIcon from '!svg-inline-loader!images/icons/filter_german_language_skills.svg';
import targetGroupIcon from '!svg-inline-loader!images/icons/filter_target_group.svg';
import topicIcon from '!svg-inline-loader!images/icons/filter_topic.svg';
import frequencyIcon from '!svg-inline-loader!images/icons/filter_frequency.svg';
import noProjectsFoundImage from '!svg-inline-loader!images/project_search/no_projects.svg';
import { MOBILE_VIEW_UNTIL_WIDTH } from '../../common/vars';
import { isUserAgentIE } from '../../common/helper';
import { setLocale, t } from '../helpers';
import ButtonIcon from '../ButtonIcon';
import FilterDateRange from '../FilterDateRange';
import FilterCheckboxGroup from '../FilterCheckboxGroup';
import FilterRadioButtonGroup from '../FilterRadioButtonGroup';

const DATE_QUERY_FORMAT = 'yyyy-MM-dd';
const MODE_LIST = 'list';
const MODE_MAP = 'map';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.bindMethodsToThis();
    setLocale(this.props.locale);
    this.projectsPath = this.props.projects_path;
    this.volunteeringDatesPath = this.props.volunteering_dates_path;
    this.filters = this.separateFilters(this.props.search_filter_model.all_filters);
    this.searchLabels = this.props.search_labels;
    this.mockGoogleMaps = this.props.mock_google_maps;
    this.googleMapsKey = this.props.google_maps_key;
    this.state = this.props.initial_state;
    this.state.locationSearchSubmitted = true;
    this.state.textSearchInput = this.state.textSearch ? this.state.textSearch : '';
    if (this.state.dateRange) {
      this.state.dateRange[0] = this.parseQueryDate(this.state.dateRange[0]);
      this.state.dateRange[1] = this.parseQueryDate(this.state.dateRange[1]);
    }
    this.state.selectedTargetGroups = [];
    this.state.selectedTopics = [];
    this.state.selectedFrequencies = [];
    this.state.isLoading = false;
    this.state.loadMoreCount = this.props.load_more_count;
    if (this.props.data.volunteering_dates) {
      this.state.projects = map(this.props.data.volunteering_dates, volunteeringDate => {
        const project = volunteeringDate.project;
        project.date = {
          start: volunteeringDate.start,
          end: volunteeringDate.end,
        };
        return project;
      });
    } else {
      this.state.projects = this.props.data.projects;
    }
    this.state.numOfProjects = this.props.data.num_of_projects;
    this.state.numOfProjectsLocationDependent = this.props.data.num_of_projects_location_dependent;
    this.state.moreProjects = this.props.data.num_of_projects - this.state.projects.length;
    this.state.loadMoreLocationIndependent = this.props.data.num_of_projects_location_dependent == this.state.projects.length;
    this.state.activeFilters = this.activeFilters(this.state);
    this.state.fetchMode = this.state.mode;
    this.state.query = this.generateQuery(this.state);
    this.state.previousQuery = this.state.query;
    this.state.previewNumOfMatches = null;
  }

  bindMethodsToThis() {
    this.submitForm = this.submitForm.bind(this);
    this.placeChanged = this.placeChanged.bind(this);
    this.textSearchChanged = this.textSearchChanged.bind(this);
    this.onlyLocationIndependentChanged = this.onlyLocationIndependentChanged.bind(this);
    this.locationSearchFocused = this.locationSearchFocused.bind(this);
    this.locationSearchBlured = this.locationSearchBlured.bind(this);
    this.onDateRangeConfirm = this.onDateRangeConfirm.bind(this);
    this.search = this.search.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.showList = this.showList.bind(this);
    this.showMap = this.showMap.bind(this);
    this.initLocationSearchAutocomplete = this.initLocationSearchAutocomplete.bind(this);
    this.googleMapsLoaded = this.googleMapsLoaded.bind(this);
    this.mapBoundsChange = this.mapBoundsChange.bind(this);
  }

  separateFilters(filters) {
    return reduce(
      filters,
      (separated, value) => {
        if (!separated[value.type]) {
          separated[value.type] = [];
        }
        separated[value.type].push(value);
        return separated;
      },
      {}
    );
  }

  componentDidMount() {
    if (this.mockGoogleMaps) {
      vostel.initMockGmaps(this.initLocationSearchAutocomplete);
    } else {
      document.addEventListener('googleMapsLoaded', this.initLocationSearchAutocomplete);
      var script;
      script = document.createElement('script');
      script.src = '/javascripts/markerclusterer-2.5.3.umd.min.js';
      script.onload = this.googleMapsLoaded;
      document.body.appendChild(script);
      script = document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.googleMapsKey + '&libraries=places&language=de-DE';
      script.onload = this.googleMapsLoaded;
      document.body.appendChild(script);
    }
    window.onpopstate = (function(event) {
      if (event.state) {
        var newState = event.state;
        for (var prop in this.state) {
          if (!newState[prop]) {
            if ('locationSearch' == prop || 'textSearchInput' == prop) {
              newState[prop] = '';
            } else {
              newState[prop] = null;
            }
          }
        }
        newState.onpopstate = true;
        this.locationSearch.value = newState.locationSearch ? newState.locationSearch : '';
        this.updateFiltersAndResults(newState);
      }
    }).bind(this);
    history.replaceState(this.historyState(), '');

    let userAgentIE = isUserAgentIE();
    let stateUpdateAfterUserAgentIsDetermined = {isUserAgentIE: userAgentIE};
    if (userAgentIE) {
      // Ignore query param "mode=map"
      stateUpdateAfterUserAgentIsDetermined.mode = MODE_LIST;
      stateUpdateAfterUserAgentIsDetermined.fetchMode = MODE_LIST;
    }
    this.setState(stateUpdateAfterUserAgentIsDetermined);

    this.mqList = window.matchMedia(`(max-width: ${MOBILE_VIEW_UNTIL_WIDTH}px)`)
    if (!userAgentIE) {
      this.mqList.addEventListener("change",
          () => {  
              this.onMobileBreak(this.mqList.matches);
          });
    }
    this.onMobileBreak(this.mqList.matches);
  }

  onMobileBreak(mobileView) {
    this.setState({mobileView: mobileView});
  }

  googleMapsLoaded() {
    this.googleMapsLoadedCount = this.googleMapsLoadedCount ? this.googleMapsLoadedCount + 1 : 1;
    if (2 == this.googleMapsLoadedCount) {
      (function () {
        if ( typeof window.CustomEvent === "function" ) return false;
        function CustomEvent ( event, params ) {
          params = params || { bubbles: false, cancelable: false, detail: undefined };
          var evt = document.createEvent( 'CustomEvent' );
          evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
          return evt;
        }
        CustomEvent.prototype = window.Event.prototype;
        window.CustomEvent = CustomEvent;
      })();
      var event = new CustomEvent('googleMapsLoaded');
      document.dispatchEvent(event);
    }
  }

  initLocationSearchAutocomplete() {
    var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    var isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;
    if (isFirefox && isAndroid) { // see https://stackoverflow.com/questions/29174957/google-places-autocomplete-plugin-isnt-working-in-firefox-android
        document.body.classList.add('firefox-android');
    }
    var _addEventListener = (this.locationSearch.addEventListener) ? this.locationSearch.addEventListener : this.locationSearch.attachEvent;
    var addEventListenerWrapper = function(type, listener) {
      if (type == 'keydown') {
        var origKeydownListener = listener;
        listener = function(event) {
          var suggestionSelected = document.querySelector('.pac-item-selected');
          if (event.which == 13 && !suggestionSelected) {
            if ('' == this.locationSearch.value) {
              this.clearPlace();
            } else {
              var simulatedArrowDown = new KeyboardEvent('keydown', {keyCode: 40, which: 40});
              origKeydownListener.apply(this.locationSearch, [simulatedArrowDown]);
            }
          }
          origKeydownListener.apply(this.locationSearch, [event]);
        }.bind(this);
      }
      _addEventListener.apply(this.locationSearch, [type, listener]); // add the modified listener
    }.bind(this);
    if (this.locationSearch.addEventListener) { 
      this.locationSearch.addEventListener = addEventListenerWrapper; 
    } else if (this.locationSearch.attachEvent) { 
      this.locationSearch.attachEvent = addEventListenerWrapper; 
    }
    this.autocomplete = new google.maps.places.Autocomplete(this.locationSearch, {
      types: ['(regions)'],
      componentRestrictions: {country: ['de', 'ch', 'at']},
      fields: ['address_components', 'geometry']
    });
    this.autocomplete.addListener('place_changed', this.placeChanged);
    this.locationSearch.addEventListener('focus', this.locationSearchFocused);
    this.locationSearch.addEventListener('blur', this.locationSearchBlured);
  };

  filterConfirmButtonPreviewLabel() {
    if (this.state.previewNumOfMatches === null) {
      return null;
    } else if (this.state.previewNumOfMatches === 1) {
      return `1 ${t('project_search.result')}`;
    } else {
      return `${this.state.previewNumOfMatches} ${t('project_search.results')}`;
    }
  }

  numOfResultsLabel() {
    let numOfResults;

    if (undefined == this.state.numOfProjectsLocationDependent || this.state.projects.length > this.state.numOfProjectsLocationDependent) {
      numOfResults = this.state.numOfProjects;
    } else {
      numOfResults = this.state.numOfProjectsLocationDependent;
    }

    if (numOfResults == 1) {
      return `${numOfResults} ${this.searchLabels.result_found}`;
    } else {
      return `${numOfResults} ${this.searchLabels.results_found}`;
    }
  }

  render() {
    const {
      location_independent_label,
      process_type_labels,
      frequency_labels,
      german_language_skills_labels,
    } = this.props;
    const noResults = !this.state.isLoading && !(this.state.projects && this.state.projects.length);
    return (
        <div ref={c => (this.searchContainer = c)}>
          <form action="#" onSubmit={this.submitForm}>
            <div className={'main-search-container'}>
              <div>
                <div className={'main-input-label'}>{this.searchLabels.location.heading}</div>
                <input ref={c => (this.locationSearch = c)} id="location-search" className={cN('location-search', this.state.locationSearchSubmitted ? null : 'location-search--unsubmitted')} defaultValue={this.state.locationSearch} disabled={this.state.onlyLocationIndependent} autoComplete="off" placeholder={this.searchLabels.location.placeholder} />
                <div className={'only-location-independent-wrapper'}>
                  <input type="checkbox" id="only-location-independent" className={'only-location-independent-checkbox'} checked={this.state.onlyLocationIndependent} onChange={this.onlyLocationIndependentChanged}/>
                  <label htmlFor="only-location-independent">{this.searchLabels.location.only_location_independent}</label>
                </div>
              </div>
              <div className={'text-and-button'}>
                <div>
                  <div className={'main-input-label'}>{this.searchLabels.text.heading}</div>
                  <input id="text-search" className={'text-search'} autoComplete="off" placeholder={this.searchLabels.text.placeholder} value={this.state.textSearchInput} onChange={this.textSearchChanged} />
                </div>
                <button className={'search-button'} onClick={this.search}>{this.searchLabels.button_label}</button>
              </div>
            </div>
            <div className={s['detail-search-container']}>
              <div className={s['detail-search-container__inner']}>
                <FilterDateRange
                  inactiveLabel={t('project_search.preferred_date')}
                  onDateRangeConfirm={this.onDateRangeConfirm}
                  defaultFromDate={this.state.dateRange ? this.state.dateRange[0] : null}
                  defaultToDate={this.state.dateRange ? this.state.dateRange[1] : null}
                  pickerOptions={{minDate: 'today'}}
                />
                { this.filters.german_language_skills && (<FilterRadioButtonGroup
                  inactiveLabel={t('project_search.german_skills')}
                  activeLabel={t('project_search.german_skills_short')}
                  confirmLabel={this.filterConfirmButtonPreviewLabel()}
                  icon={germanLanguageSkillsIcon}
                  radioButtons={this.filters.german_language_skills.map(skillLevel => { return { 'label': skillLevel['name'], 'key': skillLevel['value'] } })}
                  slectedRadioButton={this.state.germanLanguageSkills}
                  defaultValue={'mother_tongue'}
                  onChange={this.onCheckboxChangeCallback('selectedGermanLanguageSkills', 'germanLanguageSkills')}
                  onConfirm={this.onCheckboxConfirmCallback('germanLanguageSkills')}
                /> )}
                { this.filters.target_group && (<FilterCheckboxGroup
                  inactiveLabel={t('project_search.target_group')}
                  confirmLabel={this.filterConfirmButtonPreviewLabel()}
                  icon={targetGroupIcon}
                  checkboxes={this.filters.target_group.map(targetGroup => { return { 'label': targetGroup['name'], 'key': targetGroup['value'] } })}
                  defaultValue={this.state.targetGroups}
                  onChange={this.onCheckboxChangeCallback('selectedTargetGroups', 'targetGroups')}
                  onConfirm={this.onCheckboxConfirmCallback('targetGroups')}
                /> )}
                { this.filters.topic && (<FilterCheckboxGroup
                  inactiveLabel={t('project_search.topic')}
                  confirmLabel={this.filterConfirmButtonPreviewLabel()}
                  icon={topicIcon}
                  checkboxes={this.filters.topic.map(topic => { return { 'label': topic['name'], 'key': topic['value'] } })}
                  defaultValue={this.state.topics}
                  onChange={this.onCheckboxChangeCallback('selectedTopics', 'topics')}
                  onConfirm={this.onCheckboxConfirmCallback('topics')}
                /> )}
                { this.filters.frequency && (<FilterCheckboxGroup
                  inactiveLabel={t('project_search.frequency')}
                  confirmLabel={this.filterConfirmButtonPreviewLabel()}
                  icon={frequencyIcon}
                  checkboxes={this.filters.frequency.map(frequency => { return { 'label': frequency['name'], 'key': frequency['value'] } })}
                  defaultValue={this.state.frequencies}
                  onChange={this.onCheckboxChangeCallback('selectedFrequencies', 'frequencies')}
                  onConfirm={this.onCheckboxConfirmCallback('frequencies')}
                /> )}
              </div>
            </div>
          <ResultContainer>
            <div className={cN(s['result-container'], MODE_LIST == this.state.mode ? s['result-container--list'] : s['result-container--map'])}>
              <div className={s['result-container__inner']}>
                {this.state.isLoading ? <div className={s['loading-hint']} /> : null}
                <div className={cN("clearfix", s['results-infos'], {[s['results-infos--map']]: MODE_MAP == this.state.mode})}>
                  { !(MODE_MAP == this.state.mode && this.state.mobileView) && (
                    <div className={cN(`component-search__found-results`, s['found-results'])}>
                      {this.numOfResultsLabel()}
                    </div>
                  )}
                  { !this.state.isUserAgentIE && (
                    <div className={s['result-output-switch']}>
                      {MODE_LIST == this.state.mode && (
                        <button className={`btn btn-secondary btn-with-icon-left`} onClick={this.showMap} disabled={this.state.onlyLocationIndependent || false}>
                          <ButtonIcon icon="map.svg" />
                          {this.searchLabels.map_view}
                        </button>
                      )}
                      {MODE_MAP == this.state.mode && (
                        <button className={`btn btn-secondary btn-with-icon-left`} onClick={this.showList}>
                          <ButtonIcon icon="list.svg" />
                          {this.searchLabels.list_view}
                        </button>
                      )}
                    </div>
                  )}
                </div>
                {MODE_LIST == this.state.mode && (
                  this.state.projects.length == 0 ? (
                    <div className={s['no-projects-found']}>
                      <p className={cN(`component-search__no-projects-found-label`, s['no-projects-found-label'])}>{this.searchLabels.no_projects_found}</p>
                      <span className={cN(`component-search__no-projects-found-image`, s['no-projects-found-image'])} dangerouslySetInnerHTML={{ __html: noProjectsFoundImage }} />
                    </div>
                  ) : (
                    <ProjectList projects={this.state.projects} processTypeLabels={process_type_labels} locationIndependentLabel={location_independent_label} frequencyLabels={frequency_labels} />
                  )
                )}
                {MODE_MAP == this.state.mode && (
                  <div>
                    <button className={cN(`btn-primary--darkbg`, `btn-with-icon-left`, s['result-output-switch-button--mobile'])} onClick={this.showList} id="mobile-result-output-switch">
                      <ButtonIcon icon="list.svg" />
                      {this.searchLabels.change_filters}
                    </button>
                    <ProjectMap 
                      projects={this.state.projects}
                      latitude={this.state.latitude} longitude={this.state.longitude} locationSearch={this.state.locationSearch} mapBounds={this.state.mapBounds}
                      onMapBoundsChange={this.mapBoundsChange}
                      processTypeLabels={process_type_labels} locationIndependentLabel={location_independent_label} frequencyLabels={frequency_labels} noProjectsFoundLabel={this.searchLabels.no_projects_found} selectionLabel={this.searchLabels.selection} multipleProjectsOnLocationLabel={this.searchLabels.multiple_projects_on_location} toProjectLabel={this.searchLabels.to_project} company={this.state.company} 
                      projectsPath={this.projectsPath}
                      childrenIds={['mobile-result-output-switch']}
                      consentFormHTML={this.props.maps_consent_form_html} /> 
                  </div>
                )}
                {MODE_LIST == this.state.mode && this.state.moreProjects > 0 && (
                  <div className={s['load-more-container']}>
                    <button className="btn-primary btn-large" onClick={this.loadMore}>
                      {this.state.loadMoreLocationIndependent ? this.searchLabels.load_more_location_independent : this.searchLabels.load_more} ({this.state.moreProjects})
                    </button>
                  </div>
                )}
              </div>
            </div>
          </ResultContainer>
        </form>
      </div>
    );
  }

  submitForm(event) {
    event.preventDefault();
  }

  placeChanged() {
    try {
      const place = this.autocomplete.getPlace();
      this.updateFiltersAndResults({
        locationSearch: this.locationSearch.value,
        street: vostel.findPlacesAddressComponent(place, 'route', 'long_name'),
        houseNo: vostel.findPlacesAddressComponent(place, 'street_number', 'short_name'),
        sublocalityLevel2: vostel.findPlacesAddressComponent(place, 'sublocality_level_2', 'long_name'),
        sublocalityLevel1: vostel.findPlacesAddressComponent(place, 'sublocality_level_1', 'long_name'),
        zip: vostel.findPlacesAddressComponent(place, 'postal_code', 'short_name'),
        city: vostel.findPlacesAddressComponent(place, 'locality', 'long_name'),
        administrativeAreaLevel1: vostel.findPlacesAddressComponent(place, 'administrative_area_level_1', 'long_name'),
        country: vostel.findPlacesAddressComponent(place, 'country', 'long_name'),
        locationSearchSubmitted: true,
        textSearch: this.state.textSearchInput,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        initialMapBounds: null,
        mapBounds: null,
      });
    } catch (e) {
      // ignore
    }
  }

  locationSearchFocused() {
    this.setState({
      locationSearchSubmitted: true,
    });
    if (this.locationSearchBlurTimeout) {
      window.clearTimeout(this.locationSearchBlurTimeout);
    }
  }

  locationSearchBlured() {
    if ('' == this.locationSearch.value) {
      this.clearPlace();
    } else {
      this.locationSearchBlurTimeout = window.setTimeout(function() {
        this.setState({
          locationSearchSubmitted: this.locationSearch.value == this.state.locationSearch,
        });
      }.bind(this), 300);
    }
  }

  clearPlace() {
    this.updateFiltersAndResults({
      locationSearch: '',
      street: null,
      houseNo: null,
      sublocalityLevel2: null,
      sublocalityLevel1: null,
      zip: null,
      city: null,
      administrativeAreaLevel1: null,
      country: null,
      locationSearchSubmitted: true,
      textSearch: this.state.textSearchInput,
    });
  }

  textSearchChanged(event) {
    this.setState({textSearchInput: event.target.value});
  }

  onlyLocationIndependentChanged(event) {
    var newState = {
      onlyLocationIndependent: event.target.checked,
      textSearch: this.state.textSearchInput,
    };
    if (event.target.checked) {
      newState.previousLocation = {
        locationSearch: this.state.locationSearch,
        street: this.state.street,
        houseNo: this.state.houseNo,
        sublocalityLevel2: this.state.sublocalityLevel2,
        sublocalityLevel1: this.state.sublocalityLevel1,
        zip: this.state.zip,
        city: this.state.city,
        administrativeAreaLevel1: this.state.administrativeAreaLevel1,
        country: this.state.country,
      };
      newState.locationSearch = '';
      newState.street = null;
      newState.houseNo = null;
      newState.sublocalityLevel2 = null;
      newState.sublocalityLevel1 = null;
      newState.zip = null;
      newState.city = null;
      newState.administrativeAreaLevel1 = null;
      newState.country = null;
      newState.locationSearchSubmitted = true;
      newState.fetchMode = MODE_LIST;
      newState.initialMapBounds = null;
      newState.mapBounds = null;
      this.locationSearch.value = null;
    } else {
      if (this.state.previousLocation) {
        newState.locationSearch = this.state.previousLocation.locationSearch || '';
        newState.street = this.state.previousLocation.street;
        newState.houseNo = this.state.previousLocation.houseNo;
        newState.sublocalityLevel2 = this.state.previousLocation.sublocalityLevel2;
        newState.sublocalityLevel1 = this.state.previousLocation.sublocalityLevel1;
        newState.zip = this.state.previousLocation.zip;
        newState.city = this.state.previousLocation.city;
        newState.administrativeAreaLevel1 = this.state.previousLocation.administrativeAreaLevel1;
        newState.country = this.state.previousLocation.country;
        newState.locationSearchSubmitted = true;
        this.locationSearch.value = this.state.previousLocation.locationSearch || '';
      }
    }
    this.updateFiltersAndResults(newState);
  }

  search() {
    this.updateFiltersAndResults({
      textSearch: this.state.textSearchInput,
    });
    if (document.activeElement != document.body) {
      document.activeElement.blur();
    }
  }

  onDateRangeConfirm(fromDate, toDate) {
    if (fromDate && toDate) {
      this.setDateRange([fromDate, toDate]);
    } else {
      this.setDateRange(null);
    }
  }

  onCheckboxChangeCallback(stateToSetKey, stateToPreviewKey) {
    return function(selectedCheckboxes) {
      // Update selected checkboxes so that number of matching projects is updated.
      this.updateStateAndNumOfMatches({
        textSearch: this.state.textSearchInput,
        [stateToSetKey]: selectedCheckboxes
      }, {
        [stateToPreviewKey]: selectedCheckboxes
      });
    }.bind(this);
  }

  onCheckboxConfirmCallback(key) {
    return function(selectedCheckboxes) {
      // Show matching projects.
      this.updateFiltersAndResults({
        previewNumOfMatches: null,
        [key]: selectedCheckboxes,
        textSearch: this.state.textSearchInput,
      });
    }.bind(this);
  }

  setDateRange(range) {
    this.updateFiltersAndResults({
      previewNumOfMatches: null,
      dateRange: range,
      textSearch: this.state.textSearchInput,
    });
  }

  showList(event) {
    this.updateFiltersAndResults({
      fetchMode: MODE_LIST,
    });
  }

  showMap(event) {
    this.updateFiltersAndResults({
      fetchMode: MODE_MAP,
    });
  }

  mapBoundsChange(event) {
    const north = event.detail[0];
    const east = event.detail[1];
    const south = event.detail[2];
    const west = event.detail[3];
    const mapBounds = north + '/' + east + '/' + south + '/' + west;
    if (this.state.initialMapBounds) {
      this.updateFiltersAndResults({
        mapBounds: mapBounds,
        locationSearch: '',
        street: null,
        houseNo: null,
        sublocalityLevel2: null,
        sublocalityLevel1: null,
        zip: null,
        city: null,
        administrativeAreaLevel1: null,
        country: null,
        locationSearchSubmitted: true,
        textSearch: this.state.textSearchInput,
      });
      this.locationSearch.value = null;
    } else {
      this.setState({initialMapBounds: mapBounds});
    }
  }

  updateFiltersAndResults(stateToSet) {
    if (!stateToSet.onpopstate) {
      stateToSet.onpopstate = false;
    }
    var newState = {};
    _.assign(newState, this.state, stateToSet);
    newState.activeFilters = this.activeFilters(newState);
    newState.query = this.generateQuery(newState);
    this.setState(newState, this.updateResults);
  }

  updateStateAndNumOfMatches(stateToSet, stateToPreview, callback) {
    var newState = {};
    _.assign(newState, this.state, stateToSet);
    var previewState = {};
    _.assign(previewState, newState, stateToPreview);
    previewState.activeFilters = this.activeFilters(previewState);
    newState.numOfMatchesQuery = this.generateQuery(previewState);
    newState.numOfMatchesDateRange = stateToPreview.dateRange != undefined ? stateToPreview.dateRange : newState.dateRange;
    this.setState(newState, callback || this.updateNumOfMatches);
  }

  activeFilters(state) {
    var activeFilters = {};
    forEach(['locationSearch', 'sublocalityLevel2', 'sublocalityLevel1', 'zip', 'city', 'administrativeAreaLevel1', 'country', 'mapBounds'], function(key) {
      activeFilters[key] = state[key];
    });
    activeFilters['onlyLocationIndependent'] = state.onlyLocationIndependent;
    forEach(['textSearch', 'dateRange', 'germanLanguageSkills'], function(key) {
      activeFilters[key] = state[key];
    });
    forEach(['targetGroups', 'topics', 'frequencies'], function(key) {
      activeFilters[key] = state[key].length > 0;
    });
    return activeFilters;
  }

  generateQuery(state) {
    const { activeFilters, locationSearch, sublocalityLevel2, sublocalityLevel1, zip, city, administrativeAreaLevel1, country, textSearch, onlyLocationIndependent, germanLanguageSkills, dateRange, targetGroups, topics, frequencies, fetchMode, mapBounds } = state;
    let query = '';
    if (activeFilters['locationSearch']) {
      query = query.concat((query.length ? '&' : '') + 'location_search' + '=' + encodeURIComponent(locationSearch));
    }
    if (activeFilters['sublocalityLevel2']) {
      query = query.concat((query.length ? '&' : '') + 'sublocality_level_2' + '=' + encodeURIComponent(sublocalityLevel2));
    }
    if (activeFilters['sublocalityLevel1']) {
      query = query.concat((query.length ? '&' : '') + 'sublocality_level_1' + '=' + encodeURIComponent(sublocalityLevel1));
    }
    if (activeFilters['zip']) {
      query = query.concat((query.length ? '&' : '') + 'zip' + '=' + encodeURIComponent(zip));
    }
    if (activeFilters['city']) {
      query = query.concat((query.length ? '&' : '') + 'city' + '=' + encodeURIComponent(city));
    }
    if (activeFilters['administrativeAreaLevel1']) {
      query = query.concat((query.length ? '&' : '') + 'administrative_area_level_1' + '=' + encodeURIComponent(administrativeAreaLevel1));
    }
    if (activeFilters['country']) {
      query = query.concat((query.length ? '&' : '') + 'country' + '=' + encodeURIComponent(country));
    }
    if (activeFilters['textSearch']) {
      query = query.concat((query.length ? '&' : '') + 'text_search' + '=' + encodeURIComponent(textSearch));
    }
    if (activeFilters['onlyLocationIndependent']) {
      query = query.concat((query.length ? '&' : '') + 'only_location_independent' + '=' + encodeURIComponent(onlyLocationIndependent));
    }
    if (activeFilters['dateRange']) {
      query = query.concat((query.length ? '&' : '') + 'from_date' + '=' + encodeURIComponent(this.formatDate(dateRange[0], DATE_QUERY_FORMAT)));
      query = query.concat((query.length ? '&' : '') + 'to_date' + '=' + encodeURIComponent(this.formatDate(dateRange[1], DATE_QUERY_FORMAT)));
    }
    if (activeFilters['germanLanguageSkills']) {
      query = query.concat((query.length ? '&' : '') + 'german_language_skills' + '=' + encodeURIComponent(germanLanguageSkills));
    }
    if (activeFilters['targetGroups']) {
      forEach(targetGroups, (targetGroup, i) => {
        query = query.concat((query.length ? '&' : '') + 'target_group[' + i + ']' + '=' + encodeURIComponent(targetGroup));
      });
    }
    if (activeFilters['topics']) {
      forEach(topics, (topic, i) => {
        query = query.concat((query.length ? '&' : '') + 'topic[' + i + ']' + '=' + encodeURIComponent(topic));
      });
    }
    if (activeFilters['frequencies']) {
      forEach(frequencies, (frequency, i) => {
        query = query.concat((query.length ? '&' : '') + 'frequency[' + i + ']' + '=' + encodeURIComponent(frequency));
      });
    }
    if (MODE_MAP == fetchMode) {
      query = query.concat((query.length ? '&' : '') + 'mode' + '=' + encodeURIComponent(fetchMode));
    }
    if (activeFilters['mapBounds']) {
      query = query.concat((query.length ? '&' : '') + 'map_bounds' + '=' + encodeURIComponent(mapBounds));
    }
    return query;
  }

  updateResults() {
    if (!this.state.mobileView) {
      this.scrollToTopOfFilters();
    }
    if (this.state.previousQuery != this.state.query) {
      this.setState({
        previousQuery: this.state.query,
        isLoading: true,
      }, this.fetchProjects);
    }
  }

  scrollToTopOfFilters() {
    if (this.state.scrolledToTopOfFilters) {
      return;
    }
    const scrollToY = this.searchContainer.offsetTop - 15;
    if (window.scrollY < scrollToY) {
      window.scrollTo({ top: scrollToY, left: 0, behavior: 'smooth' });
    }
    this.setState({
      scrolledToTopOfFilters: true,
    });
  }

  fetchProjects(loadMore = false) {
    const query = this.state.query;
    var historyStateQueryAndAnchor = query ? '?' + query : '';
    var requestQuery = query;
    var loadMoreCount = 0;
    if (loadMore) {
      requestQuery = requestQuery.concat((requestQuery.length ? '&' : '') + 'offset' + '=' + this.state.projects.length);
      loadMoreCount = this.state.loadMoreCount + 1;
      historyStateQueryAndAnchor = historyStateQueryAndAnchor.concat((historyStateQueryAndAnchor.length ? '&' : '?') + 'load_more' + '=' + encodeURIComponent(loadMoreCount));
    }
    const apiPath = this.projectsPath;
    if (window.ga) {
      ga('set', { page: location.href, title: document.title });
      ga('send', 'pageview');
    }
    var apiPathAfterLanguage = apiPath.match(/^\/\w\w(\/.*)$/)[1];
    forEach(document.getElementsByClassName('language-switch__link'), function(element) {
      var href = element.getAttribute('href');
      var language = href.match(/^\/(\w\w)\//)[1];
      element.setAttribute('href', '/' + language + apiPathAfterLanguage + (query !== '' ? '?' + query : ''));
    });
    api.projects.get(apiPath + '?' + requestQuery).then(response => {
      const currentProjects = loadMore ? this.state.projects || [] : [];
      let newProjects;
      if (response.volunteering_dates) {
        newProjects = map(response.volunteering_dates, p => {
          const project = p.project;
          project.date = {
            start: p.start,
            end: p.end,
          };
          return project;
        });
      } else {
        newProjects = response.projects;
      }
      const projects = currentProjects.concat(newProjects);
      this.setState({
        projects: projects,
        isLoading: false,
        loadMore: loadMore,
        loadMoreCount: loadMoreCount,
        numOfProjects: response.num_of_projects,
        numOfProjectsLocationDependent: response.num_of_projects_location_dependent,
        moreProjects: response.num_of_projects - projects.length,
        loadMoreLocationIndependent: response.num_of_projects_location_dependent == projects.length,
        scrollX: window.scrollX,
        scrollY: window.scrollY,
        historyStateUrl: window.location.origin + apiPath + historyStateQueryAndAnchor,
        mode: this.state.fetchMode,
      }, this.stateUpdatedAfterLoadingProjects);
    });
  }

  stateUpdatedAfterLoadingProjects() {
    if (!this.state.onpopstate) {
      if (this.state.loadMore) {
        history.replaceState(this.historyState(), '', this.state.historyStateUrl);
      } else {
        history.pushState(this.historyState(), '', this.state.historyStateUrl);
      }
    }
    window.scrollTo(this.state.scrollX, this.state.scrollY);
  }

  updateNumOfMatches() {
    const apiPath = this.projectsPath + '/num_of_projects';
    api.projects.get(apiPath + '?' + this.state.numOfMatchesQuery).then(response => {
      this.setState({
        previewNumOfMatches: response.num_of_projects,
      });
    });
  }

  loadMore() {
    this.fetchProjects(true);
  }

  historyState() {
    var historyState = Object.assign({}, this.state);
    delete historyState.isLoading;
    return historyState;
  }

  formatDate(date, dateFormat) {
    return format(new Date(date), dateFormat);
  }

  parseQueryDate(date) {
    return new Date(date).toString();
  }
}

export default Search;
