import { Controller } from "@hotwired/stimulus"
import { Loader } from "@googlemaps/js-api-loader"

// Connects to data-controller="googlemaps"
export default class extends Controller {
  static values = {
    apikey: String,
    testMode: Boolean,
    options: Object,
    markers: Array
  }

  static targets = [ 'map' ]

  connect() {
    if (this.testModeValue) return;

    const loader = new Loader({
      apiKey: this.apikeyValue,
      version: 'weekly'
    });

    let map;

    loader.importLibrary('maps').then(function({ Map }) {
      map = new Map(this.mapTarget, this.optionsValue);
    }.bind(this));

    if (this.markersValue.length) {
      loader.importLibrary('marker').then(function({ Marker }) {
        for (const markerOptions of this.markersValue) {
          new Marker({
            map,
            ...markerOptions
          });
        }
      }.bind(this));
    }
  }
}
