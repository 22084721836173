import { Controller } from "@hotwired/stimulus"
import { Loader } from "@googlemaps/js-api-loader"

// Connects to data-controller="googleplaces"
export default class extends Controller {
  static targets = [
    'input',
    'street',
    'houseNo',
    'sublocalityLevel2',
    'sublocalityLevel1',
    'zip',
    'city',
    'administrativeAreaLevel1',
    'country',
    'latitude',
    'longitude'
  ]
  static values = {
    apikey: String,
    mock: Boolean,
    types: Array
  }

  connect() {
    // The following object maps component types (given by a response of the Google Places API)
    // to stimulus targets, default values and mock values. The stimulus targets may not exist, so
    // we use the plural form and '[0]'.
    //
    // The latitude/longitude targets are omitted here because they must be handled separately due
    // to the format of the API response (see onPlaceChange).
    this.componentTypeToFormData = {
      'sublocality_level_1': {
        target: this.sublocalityLevel1Targets[0],
        defaultValue: '',
        mockValue: 'mock sublocalityLevel1'
      },
      'sublocality_level_2': {
        target: this.sublocalityLevel2Targets[0],
        defaultValue: '',
        mockValue: 'mock sublocalityLevel2'
      },
      'postal_code': {
        target: this.zipTargets[0],
        defaultValue: '',
        mockValue: '12114'
      },
      'locality': {
        target: this.cityTargets[0],
        defaultValue: '',
        mockValue: 'mock city'
      },
      'administrative_area_level_1': {
        target: this.administrativeAreaLevel1Targets[0],
        defaultValue: '',
        mockValue: 'mock administrativeAreaLevel1'
      },
      'country': {
        target: this.countryTargets[0],
        defaultValue: '',
        mockValue: 'mock country'
      },
      'route': {
        target: this.streetTargets[0],
        defaultValue: '',
        mockValue: 'mock street'
      },
      'street_number': {
        target: this.houseNoTargets[0],
        defaultValue: '',
        mockValue: '12'
      }
    };
    
    if (this.mockValue) {
      this.setTargetValuesTo('mockValue');
      return;
    }

    if (this.apikeyValue == '') {
      return;
    }

    const loader = new Loader({
      apiKey: this.apikeyValue,
      version: 'weekly',
      // The loader sets the language by default to the preferred browser language. Since we persist
      // the the places information of projects and use it for searching by location, we need a
      // fixed language. Otherwise a project might have, say, the country "Germany" and won't be
      // found when searching for "Deutschland".
      language: 'de'
    });

    loader.importLibrary('places').then(function() {
      const options = {
        componentRestrictions: { country: ['de', 'ch', 'at'] },
        fields: ['address_components', 'geometry', 'formatted_address'],
      }

      if (this.hasTypesValue) {
        options['types'] = this.typesValue;
      }

      this.autocomplete = new google.maps.places.Autocomplete(this.inputTarget, options);

      // Autocomplete#addListener returns an id for the listener that can be used with
      // google.maps.event.removeListener:
      // https://developers.google.com/maps/documentation/javascript/reference/event#MVCObject.addListener
      this.placeChangedListenerId = this.autocomplete
        .addListener('place_changed', this.onPlaceChange.bind(this));
    }.bind(this));
  }

  disconnect() {
    if (window.google && this.placeChangedListenerId) {
      google.maps.event.removeListener(this.placeChangedListenerId);
    }
  }
  
  onPlaceChange() {
    const place = this.autocomplete.getPlace();
    
    if (place.address_components === undefined) {
      return;
    }

    this.setDetailsToDefault();

    for (const component of place.address_components) {
      const componentType = component.types[0];
      const formData = this.componentTypeToFormData[componentType];
      if (formData !== undefined && formData.target !== undefined) {
        formData.target.value = component.long_name;
      }
    }

    if (this.hasLatitudeTarget) {
      this.latitudeTarget.value = place.geometry.location.lat();
    }
    if (this.hasLongitudeTarget) {
      this.longitudeTarget.value = place.geometry.location.lng();
    }

    this.dispatch('changed');
  }

  selectFirstSuggestion(event) {
    if (document.querySelector('.pac-item')) {
      if (!document.querySelector('.pac-item-selected')) {
        event.target.dispatchEvent(
          new KeyboardEvent('keydown', {
            key: 'ArrowDown',
            code: 'ArrowDown',
            keyCode: 40,
          })
        );
      }

      event.preventDefault();
    }
  }

  setDetailsToDefault() {
    this.setTargetValuesTo('defaultValue');
    
    if (this.hasLatitudeTarget) {
      this.latitudeTarget.value = '';
    }
    if (this.hasLongitudeTarget) {
      this.longitudeTarget.value = '';
    }
  }

  setTargetValuesTo(valueKey) {
    for (const targetAndData of Object.values(this.componentTypeToFormData)) {
      if (targetAndData.target) {
        targetAndData.target.value = targetAndData[valueKey];
      }
    }
  }
}
