import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="carousel"
//
// Should go on a div which has three children, two of which are slider buttons (targets
// 'left' and 'right', with data-action="carousel#scrollToX") and one of which is a list
// containing the carousel items (target 'list').
// 
// The result is a carousel which you can, on wide screens, scroll with slider buttons on the sides.
// On narrow screens, you can swipe to scroll (if you have a touchscren), or click the sides (if you
// don't). If the items are focusable, focusing them will scroll to make them entirely visible (on
// all screen sizes).
//
// This controller makes some assumptions:
// - The list must be display: flex, flex-direction: row.
// - The list must have at least three children. (Wrap it in an <% if %> if it might not.)
// - The carousel items must all have the same width; the slider buttons must have the same width.
// - The list's padding-left must equal its padding-right.
// - The width of the list must be identical to the width of the viewport. (Breaking this
//   assumption will break the scrolling through clicks on the sides in the narrow view.)
// - If the carousel items are focusable, then they must be direct descendants of
//   the <li> elements and have data-action="focus->carousel#focusItem".
// - Carousel items which are not focusable cannot contain focusable elements.
export default class extends Controller {
  static targets = ['left', 'right', 'list'];

  // width of the slider buttons which are shown in the wide view
  scrollIndicatorWidth;

  atLeftEdge = true;
  atRightEdge = false;

  // State is one of 'veryWide' (we display all carousel items simultaneously, and no sliders),
  // 'wide' (we display sliders, which are the only way to scroll),
  // 'narrow' (we don't display sliders, and users can scroll by clicking the cut-off items or
  // swiping). State gets set every time the container gets resized.
  // The two breakpoints define the maximum widths at which we are in the relevant state.
  static values = { state: String, wideStateBreakpoint: Number, narrowStateBreakpoint: Number };

  // Reading the values of these variables is expensive: it requires the browser to repaint the
  // screen . Therefore, we create a resize observer which reads them when the carousel is resized
  // and caches them in these variables.
  listInlineSize; // almost this.listTarget.offsetWidth; see handleResize for definition
  listWidth; // this.listTarget.scrollWidth
  childWidth; // offsetWidth of this.listTarget's children
  gap; // flexbox column gap of this.listTarget
  padding; // padding-left of this.listTarget
  // Accessing the scrollLeft of the list also requires the
  // browser to repaint the screen, but caching that would be much more complicated: it can change
  // when the user swipes, so we'd need at least a scrollend handler and probably also other
  // modifications. So we don't. (Unless/until we realize that it'd *really* help performance,
  // anyway.)

  carouselItemCount;

  stateValueChanged(current, old) {
    if (current === 'narrow' || current === 'veryWide') {
      this.leftTarget.style.display = this.rightTarget.style.display = 'none';
    }
  }

  listTargetConnected(list) {
    this.carouselItemCount = list.children.length;

    let controller = this;

    // We want to debounce resize events, because our resize handler is fairly expensive, and
    // we may get a *lot* of resize events if the user is click-and-dragging the side of their
    // browser window to resize it.
    // The implementation is based on https://stackoverflow.com/a/69471344.
    let observer = new ResizeObserver((function() {
      let timer;
      return function(entries) {
        clearTimeout(timer);
        timer = setTimeout(() => controller.handleResize(entries[0]), 100);
      }
    })());

    let childObserver = new ResizeObserver((function() {
      let timer;
      return function(entries) {
        clearTimeout(timer);
        timer = setTimeout(() => controller.handleChildResize(entries[0]), 100);
      }
    })());

    observer.observe(list);
    childObserver.observe(list.querySelector('li'));
  }

  listScroll() {
    // What we really want is () => this.setEdgeState() as a scrollend event handler (so our
    // edge state doesn't become too wrong when the user scrolls).
    // That was the original implementation, and it really worked *beautifully*! In supporting
    // browsers. Which don't include Safari. (Also scrollend is just really new in general.)
    // We hook on debounced scroll events instead; implementation inspired by
    // https://stackoverflow.com/a/69471344.
    clearTimeout(this.listScrollTimer);
    this.listScrollTimer = setTimeout(() => this.setEdgeState(), 100);
  }

  listMousedown(event) {
    // We want to prevent focusing here. The thing is that we scroll children into view when
    // they get focused, but we also scroll the carousel when the user clicks the sides (in the
    // narrow state), so if a user clicks on a cut-off card in the narrow state, it'll get
    // focused and we'll scroll it into the middle, and then we'll scroll the carousel -- i. e.
    // we'll scroll twice. Undesirable!
    //
    // Focusing is, in major browsers and as far as I can tell, the default action for
    // mousedown events. Click events will also focus things, but we preventDefault on them
    // for other reasons, so they're also covered. This behaviour isn't specified (again, as
    // far as I can tell), but then it's hard to imagine a browser connecting focus with some
    // *third* mouse event. (Actually auxclick might also focus things, but since we don't scroll
    // the *carousel* on auxclick, letting that happen is fine.)
    if (this.stateValue === 'narrow' && this.side(event)) {
      event.preventDefault();
    }
  }

  listClick(event) {
    if (this.stateValue !== 'narrow') return;

    // The preventDefaults are so the browser doesn't follow the link that got clicked on.
    // It's good for other reasons too: see the long comment in the mousedown event handler.
    if (!this.atRightEdge && this.side(event) === 'right') {
      event.preventDefault();
      this.scrollToRight();
    } else if (!this.atLeftEdge && this.side(event) === 'left') {
      event.preventDefault();
      this.scrollToLeft();
    }
  }

  focusItem(event) {
    if (this.stateValue === 'veryWide') return;

    let item = event.target;

    let itemIdx = Array.prototype.indexOf.call(this.listTarget.children, item.parentElement);

    let newScrollLeft;

    if (itemIdx === 0) {
      newScrollLeft = 0;
    } else if (itemIdx === this.listTarget.children.length - 1) {
      newScrollLeft = this.listWidth;
    } else if (this.stateValue === 'wide') {
      // The basic idea in this block is: if a link gets focused and any part of it is either
      // off-screen or under the sliders, then scroll it into view. Specifically, if it was
      // partly behind the right slider, scroll to the right until its right edge is at the left
      // edge of the right slider; if it was partly behind the left slider, scroll to the left
      // until its left edge is at the right edge of the left slider. Try saying that five times
      // fast.

      let itemBegins = this.padding + itemIdx * this.childWidth + itemIdx * this.gap;
      let itemEnds = itemBegins + this.childWidth;
      let scrollLeft = this.listTarget.scrollLeft;

      // if the item is partly behind the right slider (or off-screen to the right)
      if (itemEnds > scrollLeft + this.listInlineSize - this.scrollIndicatorWidth) {
        newScrollLeft = itemEnds - this.listInlineSize + this.scrollIndicatorWidth;
      }

      // if the item is partly behind the left slider (or off-screen to the left)
      if (itemBegins < scrollLeft + this.scrollIndicatorWidth) {
        newScrollLeft = itemBegins - this.scrollIndicatorWidth;
      }
    } else {
      newScrollLeft = this.scrollAmountToCenterNthItem(itemIdx);
    }

    this.listTarget.scrollLeft = newScrollLeft;

    // depressingly, we need to do this here even though it'll also run in our scrollend handler
    // -- things just don't look right if the sliders only appear 100ms after we're done scrolling
    if (this.stateValue === 'wide') this.setEdgeState(newScrollLeft);
  }

  // Does what it says on the tin. The argument is 0-indexed: pass 1 to center the 2nd item.
  // Note that the return value is for use in scrollTo, not scrollBy -- except when n = 1, of
  // course, in which case scrollTo and scrollBy are equivalent.
  // Do not use this with n = 0 or with n equal to the number of carousel items; it was not
  // intended to handle that case.
  scrollAmountToCenterNthItem(n) {
    // The calculation might look a bit cryptic, so I'll walk you through an example.
    // Consider scrolling until the second item is centered (n = 1). We want to first scroll right
    // until the second item's left edge is at the container's left edge:
    // scrollAmount = padding + childWidth + gap
    // Then we want to scroll right until that item's *midpoint* is at the container's left edge:
    // scrollAmount += childWidth / 2
    // Then we want to scroll *left* by half the width of the container, so the item's midpoint
    // is at the container's midpoint:
    // scrollAmount -= listInlineSize / 2
    // To center an arbitrary item, we just need to change the first step, going from 
    // "childWidth + gap" (really "1 * (childWidth + gap)") to "n * (childWidth + gap)".
    return this.padding + (n + 0.5) * this.childWidth + n * this.gap - this.listInlineSize / 2;
  }

  // Returns the amount to scroll by so things look right. This is the same value regardless of
  // whether you're scrolling left or right, but the sign changes; if you're scrolling right, you
  // can just take the return value, but if you're scrolling left you must negate it.
  calculateScrollAmount() {
    if ((this.atLeftEdge || this.atRightEdge) && this.stateValue === 'wide') {
      if (this.listInlineSize > 2 * (this.childWidth + this.gap)) {
        // Scroll until the first item is shown only halfway; if we're wide enough, this looks better
        // than just scrolling one full item width.
        return this.padding + this.childWidth / 2;
      } else {
        return this.scrollAmountToCenterNthItem(1);
      }
    }

    if ((this.atLeftEdge || this.atRightEdge) && this.stateValue === 'narrow') {
      // The constant "1" is not a mistake -- we want to center either the second or the
      // second-to-last item, and the amount to scroll is the same in both cases.
      return this.scrollAmountToCenterNthItem(1);
    }

    if (!this.atLeftEdge && !this.atRightEdge) {
      return this.childWidth + this.gap;
    }

    // We did not handle every possible case, but that's fine, because when state === 'veryWide',
    // we should never get called.
  }

  displaySliders() {
    this.leftTarget.style.display = this.atLeftEdge ? 'none' : 'block';
    this.rightTarget.style.display = this.atRightEdge ?  'none' : 'block';
  }

  scrollToRight() {
    let scrollAmount = this.calculateScrollAmount();
    this.listTarget.scrollBy({left: scrollAmount, behavior: 'smooth'});

    // depressingly, we need to do this here even though it'll also run in our scrollend handler
    // -- things just don't look right if the sliders only appear when we're done scrolling
    if (this.stateValue === 'wide') this.setEdgeState(scrollAmount + this.listTarget.scrollLeft);
  }

  scrollToLeft() {
    let scrollAmount = -this.calculateScrollAmount();
    this.listTarget.scrollBy({left: scrollAmount, behavior: 'smooth'});

    // depressingly, we need to do this here even though it'll also run in our scrollend handler
    // -- things just don't look right if the sliders only appear when we're done scrolling
    if (this.stateValue === 'wide') this.setEdgeState(scrollAmount + this.listTarget.scrollLeft);
  }

  setEdgeState(scrollLeft) {
    if (typeof scrollLeft === 'undefined') {
      scrollLeft = this.listTarget.scrollLeft;
    }

    this.atLeftEdge = scrollLeft <= 0;
    this.atRightEdge = Math.ceil(scrollLeft + this.listInlineSize) >= this.listWidth;

    if (this.stateValue === 'wide') {
      this.displaySliders();
    }
  }

  // Pass a mouse event, get told whether it was on the left side of the carousel (return value
  // 'left'), or on the right side (return value 'right'), or on neither (falsy return value)
  //
  // This method is only intended to work in the narrow state.
  side(event) {
    if (this.atLeftEdge && event.clientX > this.padding + this.childWidth) {
      return 'right';
    }

    if (this.atRightEdge && event.clientX < this.listInlineSize - this.childWidth - this.padding) {
      return 'left';
    }

    if (event.clientX < (this.listInlineSize - this.childWidth) / 2) {
      return 'left';
    }

    if (event.clientX > this.listInlineSize - (this.listInlineSize - this.childWidth) / 2) {
      return 'right';
    }
  }

  handleChildResize(entry) {
    this.childWidth = entry.borderBoxSize[0].inlineSize;

    // we do this here because the value might change when childWidth does
    // (without the ResizeObserver of the list firing, since of course the list's inline size
    // didn't change...) We also set the edge state, since it's possible that handleResize, which
    // would otherwise set it, runs *after* us and does *not* set it because, as far as it can
    // tell, nothing about the list's width changed since the edge state was last set.
    let newListWidth = this.listTarget.scrollWidth;
    if (newListWidth !== this.listWidth) {
      this.listWidth = newListWidth;
      this.setEdgeState();
    }

    // [SCROLL_INDICATOR_WIDTH] copied from CSS (see the note with the same tag in carousel.scss)
    // This isn't all that great; a ResizeObserver on the scroll indicators is probably a better
    // indicator of what we're trying to do, but it's a bit complicated because generally we're
    // only displaying one scroll indicator, and then only in the wide view.
    // (Why can't we just read the offsetWidth of one of the elements? Partly it's because
    // they're not always getting displayed so we'd need a bunch of ifs; partly it's because
    // when handleResize runs the browser isn't necessarily even done computing the offsetWidth.)
    this.scrollIndicatorWidth = Math.min(240, Math.ceil(this.listInlineSize - this.childWidth) / 2);
  }

  handleResize(entry) {
    const width = Math.ceil(entry.borderBoxSize[0].inlineSize);
    const newListWidth = this.listTarget.scrollWidth;

    // If we're a carousel containing Projects (the React component) we might grow or shrink on the
    // y axis (only) when the user hovers or stops hovering over one of them. We don't really care
    // about that, so if all the horizontal sizes are what they already were we just quit. (This
    // isn't just an optimization; carousels containing Projects feel noticeably worse without
    // this from a user perspective, because if we keep running we might make arrows appear when
    // they don't really need to. Specifically, when the user just scrolled to the last Project,
    // making the arrows disappear, and is hovering over the last Project, we make the arrows
    // reappear, which is undesirable.)
    if (this.listInlineSize === width && this.listWidth === newListWidth) return;

    this.listInlineSize = width;

    // if you think this can't change on a resize, like I once did -- it changes when gap does.
    this.listWidth = this.listTarget.scrollWidth;

    const listStyle = window.getComputedStyle(this.listTarget);
    this.padding = parseInt(listStyle.paddingLeft, 10);
    this.gap = parseInt(listStyle.columnGap, 10);

    if (this.wideStateBreakpointValue <= width) {
      this.stateValue = 'veryWide';
    } else if (this.narrowStateBreakpointValue <= width) {
      this.stateValue = 'wide';
    } else {
      this.stateValue = 'narrow';
    }

    this.setEdgeState();
  }
}
