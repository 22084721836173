import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="cookie"
export default class extends Controller {
  connect() {
  }

  set(event) {
    for (const cookie of event.params['cookies']) {
      let cookieString = '';
      for (const [key, value] of Object.entries(cookie)) {
        cookieString += `${key}=${value}; `
      }
      document.cookie = cookieString;
    }
  }
}
