import React from 'react';
import Project from 'components/Project';
import { setLocale } from '../helpers';

// This class exists because of the project carousel on the landing page. The projects there need
// to be rendered on the server because the carousel component can't quite handle client-side
// React. But if we render them on the server in a loop inside our .html.erb template, that's very,
// very slow. Rendering a single component of this class -- basically a JSX loop -- is faster.

export default class ProjectListCarousel extends React.Component {
  constructor(props) {
    super(props);
    setLocale(this.props.locale);
  }

  render() {
    const { projects, processTypeLabels, locationIndependentLabel, frequencyLabels } = this.props;
    return (
      <>
        {projects.map((project, idx) => (
          <li key={idx}>
            <Project {...project}
              processTypeLabels={processTypeLabels}
              locationIndependentLabel={locationIndependentLabel}
              frequencyLabels={frequencyLabels}
            />
          </li>
        ))}
      </>
    );
  }
}
