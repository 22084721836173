// Sorry about this overly specific controller. Don't use it unless you absolutely have to. See
// _maps_consent_form partial for explanation.
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="maps-consent"
export default class extends Controller {
  connect() {
  }

  setHrefToCurrentLocation() {
    this.element.href = window.location.pathname + window.location.search;
  }
}
